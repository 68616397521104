import React, { useCallback, useEffect, useState } from "react";
import { TopicsService } from "../../api/topic";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import "./askQA.scss";
import { Button } from "antd";
import { DownOutlined, DownloadOutlined, UpOutlined } from "@ant-design/icons";
import { exportToCsv } from "../../containers/chat/ChatExportUtil";
import { IQuestAns } from "../../interfaces/chat";
import { Loading } from "../Loading";
import { ChatAside } from "../../containers";
import { IPages, ISources } from "../../api";
import { useOutletContext, useParams } from "react-router-dom";
import { PAGE_URL } from "../../utils";
import { IOutletContext } from "../../navigation/PrivateLayout";
import SourceModal from "../../containers/modals/source/SourceModal";
interface AnalysisItem {
  answer: [{ que: string; ans: string }];
  sources: {
    documentUUID: string;
    fileName: string;
    pages: IPages[];
  };
}

const Analysis = () => {
  const [data, setData] = useState<AnalysisItem>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [hideChatHistory, setHideChatHistory] = useState(false);
  const [showRightContainer, setShowRightContainer] = useState(false);
  const [isQuesAnsLoading, setIsQuesAnsLoading] = useState(false);
  const [chatSource, setChatSource] = useState({} as ISources);
  const [expanded, setExpanded] = useState(false);
  const [isAnalysis, setIsAnalysis] = useState<boolean>(true);
  const { documentUuid } = useParams();
  const { setPageHeader, resetPageHeader } = useOutletContext<IOutletContext>();
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState<ISources | null>(null);
  const [selectedPage, setSelectedPage] = useState<number>(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const res = await TopicsService.getContractAnalysis(documentUuid!);
        if (!res) {
          setError("No analysis was found");
        } else {
          setData(res.data.data.analysis);
          setPageHeader({
            pageRoute: `${PAGE_URL.DASHBOARD}`,
            pageTitle: res.data.data.analysis.sources.fileName || documentUuid,
            displayBackButton: true,
          });
        }
      } catch (err) {
        setError("No analysis was found");
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      resetPageHeader();
    };
  }, [documentUuid]);

  const handleSourceClick = (source: ISources, pageNo?: number) => {
    setSelectedSource(source);
    setSelectedPage(pageNo || 1);
    setIsSourceModalOpen(true);
  };

  const handleDownloadClick = () => {
    if (data) {
      const convertedData: IQuestAns[] = data?.answer.map((item) => ({
        question: item.que,
        answer: item.ans,
        qaId: "",
        logId: 0,
        sources: [],
      }));
      exportToCsv(convertedData, ",");
    }
  };
  const getSourceNumberMap = (sources: { chunkUUID: string; pageNo: number }[]) => {
    const map: { [key: string]: number } = {};
    sources.forEach((source) => {
      map[source.chunkUUID] = source.pageNo;
    });
    return map;
  };

  // const renderAnswer = useCallback(
  //   (answer: string, sources: ISources) => {
  //     const sourceNumberMap = getSourceNumberMap(sources.pages);
  //     const parts = answer.split(
  //       /(\([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:,\s*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})*\))/
  //     );

  //     return parts.map((part, i) => {
  //       const match = part.match(
  //         /\(([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:,\s*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})*)\)/
  //       );
  //       if (match) {
  //         const chunkUUIDs = match[1].split(/,\s*/);
  //         const pageNumbers = new Set<number>();

  //         chunkUUIDs.forEach((chunkUUID) => {
  //           const pageNo = sourceNumberMap[chunkUUID];
  //           if (pageNo) {
  //             pageNumbers.add(pageNo);
  //           }
  //         });
  //         console.log(sourceNumberMap,123123);

  //         const sortedPageNumbers = Array.from(pageNumbers).sort((a, b) => a - b);
  //         const pageNumbersString = sortedPageNumbers.join(", ");

  //         return (
  //           <span
  //             key={`${chunkUUIDs.join("-")}-${i}`}
  //             className="source-number"
  //             onClick={() => handleSourceClick(sources,sortedPageNumbers[0])}
  //             style={{ cursor: "pointer", color: "#3232a6", fontWeight: "600" }}
  //           >
  //             [{pageNumbersString}]
  //           </span>
  //         );
  //       }
  //       return part;
  //     });
  //   },
  //   [handleSourceClick]
  // );
  const renderAnswer = (answer: string, sources: ISources) => {
    const sourceNumberMap = getSourceNumberMap(sources.pages);

    const parts = answer.split(
      /(\([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:[;,\s]*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})*\))/
    );

    return parts.map((part, i) => {
      const match = part.match(
        /\(([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:[;,\s]*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})*)\)/
      );
      if (match) {
        const chunkUUIDs = match[1].split(/[;,\s]+/);
        return (
          <>
            {chunkUUIDs.map((uuid, index) => {
              const pageNo = sourceNumberMap[uuid];
              if (pageNo) {
                return (
                  <span
                    key={`${uuid}-${i}-${index}`}
                    className="source-number"
                    onClick={() => handleSourceClick(sources, pageNo)}
                    style={{ cursor: "pointer", fontWeight: "600", marginRight: "2px" }}
                  >
                    [1]
                  </span>
                );
              }
              return null;
            })}
          </>
        );
      }
      return part;
    });
  };

  return (
    <div className="askqa-container">
      {!showRightContainer && (
        <div className="analysis-container">
          <h1>Analysis</h1>
          {loading ? (
            <Loading className="brand-color" />
          ) : error ? (
            <p>{error}</p>
          ) : data ? (
            <div>
              <div className="row">
                <div className="label">
                  <b>Data Element</b>
                </div>
                <div className="value">
                  <b>Value</b>
                </div>
              </div>
              {data?.answer.map((item, index) => (
                <div key={index} className="row">
                  <div className="label">{item.que}</div>
                  <div className="value">{renderAnswer(item.ans, data.sources)}</div>
                </div>
              ))}
              <div className="sources-containers">
                <div>
                  <h4>Source : </h4>
                  <div className="source-list flex gp-10">
                    <li
                      className="cursor-pointer"
                      onClick={() => {
                        handleSourceClick(data.sources);
                      }}
                    >
                      <a>{data.sources.fileName + " [1]"}</a>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <Button
            className="fill download-btn"
            title="Download Chats"
            icon={<DownloadOutlined />}
            size={"large"}
            onClick={() => {
              handleDownloadClick();
            }}
          />
        </div>
      )}
      {Object.keys(chatSource).length > 0 && (
        <div
          className={`chat-history-toggle ${!showRightContainer ? "" : "chat-history-toggle-long"}`}
          onClick={() => {
            setShowRightContainer(!showRightContainer);
            setHideChatHistory(!hideChatHistory);
          }}
          title="Chat History"
        >
          {showRightContainer ? (
            <DownOutlined className="fixed" />
          ) : (
            <UpOutlined className="fixed" />
          )}
        </div>
      )}
      {showRightContainer && (
        <div className="chat-aside">
          <ChatAside
            {...{
              hideChatHistory,
              setHideChatHistory,
              chatSource,
              setIsQuesAnsLoading,
              expanded,
              setExpanded,
              isAnalysis,
            }}
          />
        </div>
      )}
      {selectedSource && (
        <SourceModal
          open={isSourceModalOpen}
          onClose={() => setIsSourceModalOpen(false)}
          source={selectedSource}
          page={selectedPage}
        />
      )}
    </div>
  );
};

export default Analysis;
