import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { DataSourceProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { uploadFileFolderService } from "../../../api";
import {STATUS_CODE,openNotificationWithIcon } from "../../../utils";
import {
  DocViewer,
  Loading,
  NotAccessibleFallback,
  PdfViewer,
  TextViewer,
} from "../../../components";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";

export const DataSource = (props: DataSourceProps) => {
  const { directoryPath, fileName, handleClose, topicId, visibility, storage } = props;
  const [fileUrl, setFileUrl] = useState("");
  const [isTextFile, setIsTextFile] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [isDocFile, setIsDocFile] = useState(false);
  const { documentUUID }=useSelector((state:RootState) => state.fileFolderUpload);
      
  useEffect(() => {
    const getSignedurl = async () => {
      setIsLoading(true);
      try {          
        const {data} = await uploadFileFolderService.getSignedUrlByUuid(documentUUID)
        const filepath = data.filePath;
        setIsTextFile(filepath.includes(".txt"));
        setIsDocFile(filepath.endsWith(".doc") || filepath.endsWith(".docx"));
        console.debug("filepath", filepath);
        console.debug("fileUrl", data.signedUrl);
        console.debug("isDocFile", isDocFile);
        setFileUrl(data.signedUrl);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("","This file has been deleted and cannot be opened.", "error");
          handleClose();
        }
      } finally {
        setIsLoading(false);
      }
    };
    getSignedurl();
  }, [directoryPath, fileName, handleClose, isDocFile, topicId, storage]);

  // const handleFileDownload = async () => {
  //   await downloadPDF(fileUrl, `source_${new Date().getTime()}.${isTextFile ? "txt" : "pdf"}`);
  // };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={1000}
      visible={visibility}
      footer={null}
      title={"Source"}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="flex addClient-title no-bd-bt">
        <h1 className="mb-0">Source</h1>
        {/* <div className="mt-auto">
          <Button className="fill" onClick={handleFileDownload} disabled={fileUrl === ""}>
            Download
          </Button>
        </div> */}
      </div>
      <div className="addClient-content w-100 no-pd-tp">
        <>
          {isLoading ? (
            <Loading />
          ) : !isAuthorized ? (
            <NotAccessibleFallback />
          ) : isTextFile ? (
            <TextViewer fileUrl={fileUrl} />
          ) : isDocFile ? (
            <DocViewer fileUrl={fileUrl} />
          ) : (
            <PdfViewer
              pdf={fileUrl}
              displayPagination
              setCurrPage={setCurrentPage}
              currPage={currentPage}
            />
          )}
        </>
      </div>
    </Modal>
  );
};
