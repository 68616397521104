import React from "react";
import { Dropdown } from "../../../../components";
import { InformationIcon } from "../../../../assets/icons";
import { DATA_EMBEDDING_MODELS_DESCRIPTION } from "../../../../utils";

interface Props {
  embeddingModels: IDropdownOption[];
  selectedEmbeddingModel: IDropdownOption;
  setSelectedEmbeddingModel: React.Dispatch<React.SetStateAction<IDropdownOption>>;
  isDisabled: boolean;
  isDropdownsDataLoading: boolean;
}

export const DataEmbedding = ({
  embeddingModels,
  selectedEmbeddingModel,
  setSelectedEmbeddingModel,
  isDisabled,
  isDropdownsDataLoading,
}: Props) => {
  const handleEmbeddingModelChange = (value: string, option: IDropdownOption) => {
    setSelectedEmbeddingModel(option);
  };
  return (
    <div className="data-embedding white-box-bg">
      <h4>Data Embedding</h4>
      <div className="flex gp aic">
        <p>Embedding Models</p>
        <div className="flex aic" title={DATA_EMBEDDING_MODELS_DESCRIPTION}>
          <InformationIcon className="link-color" />
        </div>
      </div>
      <Dropdown
        disabled={isDisabled}
        value={selectedEmbeddingModel.value}
        options={embeddingModels.map((embeddingModel) => ({
          id: embeddingModel.id,
          value: embeddingModel.value,
          key: embeddingModel.id,
        }))}
        onSelect={handleEmbeddingModelChange}
        loading={isDropdownsDataLoading}
      />
    </div>
  );
};
