import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  openModal,
  setFeedbackType,
  setQuestionAnswerLogId,
  setQuestionsAnswers,
  updateQuestionAnswer,
} from "../../../store";
import { Answer, ChatService, IGetAnswerRequest, ISources } from "../../../api";
import { MODAL_TYPE } from "../../../utils";
import { IQuestAns } from "../../../interfaces/chat";
import { MenuIcon, ThumbsDown, ThumbsUp } from "../../../assets/icons";
import { autonomizeLogo } from "../../../assets/images";
import { Loading } from "../../../components";
import { Button, Input, InputRef, Table, Tooltip } from "antd";
import {
  CopyOutlined,
  DownOutlined,
  DownloadOutlined,
  RedoOutlined,
  SendOutlined,
  UpOutlined,
} from "@ant-design/icons";
import "./chat-ques-ans.scss";
import { exportToCsv } from "../ChatExportUtil";
import Typed from "react-typed";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { autonomizeFullLogoBlack } from "../../../assets/images";
import SourceModal from "../../modals/source/SourceModal";
import { useProjectSettings } from "../../../hooks";
import { useParams } from "react-router-dom";
import { ChatAside } from "../ChatAside";

const { Search } = Input;
const COLUMNS = [
  {
    title: "Data point",
    dataIndex: "que",
    key: "question",
  },
  {
    title: "Value",
    dataIndex: "ans",
    key: "Answer",
  },
];

interface Prompt {
  q: string;
  desc: string;
  onClick?: () => void;
}

interface Prompts {
  provider_contract_analysis: Prompt[];
  "provider-contract-analysis-configuration": Prompt[];
  multiDocQa: Prompt[];
}
interface Props {
  setChatSource: React.Dispatch<React.SetStateAction<ISources>>;
  isQuesAnsLoading: boolean;
  setIsQuesAnsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fileterOnMetadata: boolean;
  selectedFile: string;
  setHideChatHistory: React.Dispatch<React.SetStateAction<boolean>>;
  topicId: number;
  showHelp: boolean;
  setShowHelp: any;
  setShowChatAside: React.Dispatch<React.SetStateAction<boolean>>;
  isSingleDoc: boolean;
  documentUuid?: string;
  qaConfiguration?: IQAConfiguration;
}

export const ChatQuesAns = ({
  isQuesAnsLoading,
  setIsQuesAnsLoading,
  fileterOnMetadata,
  selectedFile,
  topicId,
  setShowHelp,
  showHelp,
  setShowChatAside,
  isSingleDoc = false,
  documentUuid,
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const { quesAns } = useSelector((state: RootState) => state.quesAns);
  const questionAnswerListRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<InputRef>(null);
  const [typedCompletionStatus, setTypedCompletionStatus] = useState<Array<boolean>>([false]);
  const [showPrompts, setShowPrompts] = useState(true);
  const { projectType } = useSelector((state: RootState) => state.fileFolderUpload);
  const [dataSource, setDataSource] = useState<Answer[]>([]);
  const [isTable, setIsTable] = useState<boolean>(false);
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [allPromptsEmpty, setAllPromptsEmpty] = useState<boolean | undefined>(false);
  const [selectedSource, setSelectedSource] = useState<ISources | null>(null);
  const { fetchQAConfiguration, qaConfiguration } = useProjectSettings();
  const { projectId } = useParams();
  const [showRightContainer, setShowRightContainer] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [chatSource, setChatSource] = useState({} as ISources);
  const [hideChatHistory, setHideChatHistory] = useState(true);

  const handleSearchSubmit = async (e: string, index?: number) => {
    if (e.trim().length > 0) {
      setIsQuesAnsLoading(true);
      try {
        const obj: IGetAnswerRequest = {
          q: e.trim(),
          metadataFilter: fileterOnMetadata ? 1 : 0,
          topicId: topicId,
          qaId: crypto.randomUUID(),
        };
        if (selectedFile !== "All") {
          obj.category = selectedFile;
        }
        const { data } = await ChatService.getAnswer(obj, documentUuid);

        if (index !== undefined) {
          dispatch(
            updateQuestionAnswer({
              index,
              question: e.trim(),
              answer: data.data.answer,
              logId: data.data.logId,
              sources: data.data.sources,
              qaId: data.data.qaId,
            })
          );
          setTypedCompletionStatus((prev) => {
            const newStatus = [...prev];
            newStatus[index] = false;
            return newStatus;
          });
        } else {
          dispatch(
            setQuestionsAnswers([
              ...quesAns,
              {
                question: e.trim(),
                answer: data.data.answer,
                logId: data.data.logId,
                sources: data.data.sources,
                qaId: data.data.qaId,
              },
            ])
          );
        }
        setInputValue("");
      } catch (error: any) {
        // openNotificationWithIcon("", error.response.data.message, "error");
      } finally {
        setIsQuesAnsLoading(false);
      }
    }
  };

  const handleThumbsClick = (item: IQuestAns, type: "up" | "down") => {
    dispatch(
      setQuestionAnswerLogId({
        logId: item.logId,
        qaId: item.qaId,
      })
    );
    dispatch(setFeedbackType(type));
    dispatch(
      openModal({
        modalName: MODAL_TYPE.FEEDBACK,
        dataSource: "",
      })
    );
  };

  const handleCopyToClipboard = (item: string | Answer[]) => {
    if (typeof item === "string") {
      navigator.clipboard.writeText(item);
    }
  };

  useEffect(() => {
    if (questionAnswerListRef.current) {
      questionAnswerListRef.current.scrollTo({
        top: questionAnswerListRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    setShowPrompts(!quesAns.length);
  }, [quesAns.length]);

  useEffect(() => {
    fetchQAConfiguration(projectId);
  }, [projectId]);

  const handleSourceClick = (source: ISources) => {
    setSelectedSource(source);
    setIsSourceModalOpen(true);
  };

  const handleCloseSourceModal = () => {
    setIsSourceModalOpen(false);
  };

  const handleDownloadClick = () => {
    exportToCsv(quesAns, ",");
  };

  const handleTypedComplete = (index: number) => {
    setTypedCompletionStatus((prev) => {
      const newStatus = [...prev];
      newStatus[index] = true;
      return newStatus;
    });
  };

  const isTableString = (inputString: string) => {
    const tableRegex = /^\s*\|.*\|\s*$/m;
    return tableRegex.test(inputString);
  };

  const convertStringToTable = (inputString: string) => {
    return <Markdown remarkPlugins={[remarkGfm]}>{inputString}</Markdown>;
  };
  const renderPrompts = () => {
    return (
      <div className="ask-auto-prompts single-doc-ask-auto-prompts">
        {qaConfiguration.projectPrompts
          ?.filter((item) => item.question && item.question.trim())
          .map((prompt) => (
            <div
              className="ask-auto-prompts-single"
              onClick={() => {
                setInputValue(prompt.question);
                searchInputRef.current?.focus();
              }}
              key={prompt.question}
            >
              <p>{prompt.question}</p>
            </div>
          ))}
      </div>
    );
  };

  useEffect(() => {
    setAllPromptsEmpty(qaConfiguration.projectPrompts?.every((item) => !item.question.trim()));
  }, [qaConfiguration]);

  const getSourceNumberMap = (sources: ISources[]) => {
    const map: { [key: string]: { source: ISources; sourceNumber: number } } = {};
    sources.forEach((source, index) => {
      source.pages.forEach((page) => {
        map[page.chunkUUID] = { source, sourceNumber: index + 1 };
      });
    });
    return map;
  };

  const renderAnswer = useCallback(
    (answer: string, sources: ISources[], index: number, typedCompleted: boolean) => {
      const sourceNumberMap = getSourceNumberMap(sources);
      const parts = answer.split(
        /(\([^)]*?[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}[^)]*\)|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:[;,\s]*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})*)/
      );

      const processedParts = parts.map((part, i) => {
        const matchWithBrackets = part.match(
          /\(([^)]*?)([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:[;,\s]*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})*)([^)]*)\)/
        );
        const matchWithoutBrackets = part.match(
          /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:[;,\s]*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})*)/
        );

        if (matchWithBrackets || matchWithoutBrackets) {
          let prefixInfo = "",
            uuidsPart = "",
            suffixInfo = "";

          if (matchWithBrackets) {
            [, prefixInfo, uuidsPart, suffixInfo] = matchWithBrackets;
          } else if (matchWithoutBrackets) {
            uuidsPart = matchWithoutBrackets[1];
          } else {
            // If both are null, return the original part
            return part;
          }

          const chunkUUIDs = uuidsPart.split(/[;,\s]+/);
          const sourceNumbers = new Set<number>();
          const sourcesSet = new Set<ISources>();

          chunkUUIDs.forEach((chunkUUID) => {
            const sourceInfo = sourceNumberMap[chunkUUID];
            if (sourceInfo) {
              sourceNumbers.add(sourceInfo.sourceNumber);
              sourcesSet.add(sourceInfo.source);
            }
          });

          const sortedSourceNumbers = Array.from(sourceNumbers).sort((a, b) => a - b);
          const sourceNumbersString = sortedSourceNumbers.join(", ");

          return typedCompleted ? (
            <span key={`${chunkUUIDs.join("-")}-${i}`}>
              <span>{prefixInfo}</span>
              <span
                className="source-number"
                onClick={() => handleSourceClick(Array.from(sourcesSet)[0])}
                style={{ cursor: "pointer", fontWeight: "600" }}
              >
                [{sourceNumbersString}]
              </span>
              <span>{suffixInfo}</span>
            </span>
          ) : (
            `${prefixInfo}[${sourceNumbersString}]${suffixInfo}`
          );
        }
        return part;
      });

      const content = processedParts.join("");

      return (
        <>
          {typedCompleted ? (
            <div>{processedParts}</div>
          ) : (
            <Typed
              strings={[content]}
              typeSpeed={1}
              showCursor={false}
              onComplete={() => handleTypedComplete(index)}
            />
          )}
        </>
      );
    },
    [typedCompletionStatus, handleSourceClick, handleTypedComplete]
  );
  return (
    <div className={`ask-question`}>
      {!showRightContainer && (
        <div className="ask-question-left-conatiner">
          <div
            ref={questionAnswerListRef}
            style={{ maxHeight: "calc(100% - 90px)", overflowY: "auto" }}
          >
            {!quesAns.length && (
              <div className="ask-question-empty">
                <img src={autonomizeFullLogoBlack} alt="Ask Auto" className="autonomize-logo" />
              </div>
            )}
            {quesAns.map((item, index) => (
              <div className="ask-question-result" key={`${index}-${item.qaId}`}>
                <div className="ask-question-result-ques ">
                  <p className="mb-0 text-wrap word-break-all">{item.question}</p>
                </div>
                <div className="ask-question-result-answer">
                  <img className="ask-question-result-answer-img" src={autonomizeLogo} />
                  <div className="ask-question-result-answer-content">
                    <p className="ask-question-answer m-b">
                      {typeof item.answer === "string" ? (
                        <>
                          {isTableString(item.answer)
                            ? convertStringToTable(item.answer)
                            : renderAnswer(
                                item.answer,
                                item.sources,
                                index,
                                typedCompletionStatus[index]
                              )}
                        </>
                      ) : (
                        <Table dataSource={item.answer} columns={COLUMNS} pagination={false} />
                      )}
                    </p>
                    {(typedCompletionStatus?.[index] ||
                      (typeof item.answer === "string" && isTableString(item.answer)) ||
                      Array.isArray(item.answer)) && (
                      <>
                        <div className="source-list flex gp-10 m-b">
                          {item.sources.length > 0 && (
                            <div>
                              <div className="mb-10">
                                <h5 className="mb-5">Sources:</h5>
                                <li
                                  className="cursor-pointer"
                                  onClick={() => handleSourceClick(item.sources[0])}
                                >
                                  <a>{`${item.sources[0].fileName}[1]`}</a>
                                </li>
                              </div>
                              {item.sources.length > 1 && (
                                <>
                                  <h5 className="mb-5">See also:</h5>
                                  <div className="flex">
                                    {item.sources.slice(1).map((source, i) => (
                                      <li
                                        className="cursor-pointer mb-5"
                                        key={i}
                                        onClick={() => handleSourceClick(source)}
                                      >
                                        <a>{`${source.fileName}[${i + 2}]`} </a>
                                      </li>
                                    ))}
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="flex gp-10">
                          <div
                            onClick={() => handleThumbsClick(item, "up")}
                            className="cursor-pointer flex"
                          >
                            <ThumbsUp />
                          </div>
                          <div
                            onClick={() => handleThumbsClick(item, "down")}
                            className="cursor-pointer flex"
                          >
                            <ThumbsDown />
                          </div>
                          <Tooltip className="cursor-pointer flex">
                            <CopyOutlined
                              style={{ fontSize: "18px", color: "#635F6A" }}
                              onClick={() => handleCopyToClipboard(item.answer)}
                            />
                          </Tooltip>
                          <Tooltip className="cursor-pointer flex">
                            <RedoOutlined
                              style={{ fontSize: "18px", color: "#635F6A" }}
                              onClick={() => handleSearchSubmit(item.question, index)}
                            />
                          </Tooltip>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="ask-question-search-bar">
            {!allPromptsEmpty && qaConfiguration.projectPrompts ? (
              <div className="ask-auto-prompts-container">
                <div
                  className={`ask-auto-prompts-toggle ${
                    showPrompts ? "" : "ask-auto-prompts-toggle-short"
                  }`}
                  onClick={() => setShowPrompts((prev) => !prev)}
                  title="Prompts"
                >
                  {!showPrompts ? <UpOutlined /> : <DownOutlined />}
                </div>

                {showPrompts && renderPrompts()}
              </div>
            ) : (
              <></>
            )}
            <div className="grid ask-question-search-bar-container m-b">
              <Search
                className="record-search"
                placeholder="Ask your question"
                enterButton={
                  isQuesAnsLoading ? <Loading tip="" /> : <Button icon={<SendOutlined />} />
                }
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onSearch={(e) => handleSearchSubmit(e)}
                disabled={isQuesAnsLoading}
                ref={searchInputRef}
              />
              <Button
                className="fill h-full"
                title="Download Chats"
                icon={<DownloadOutlined />}
                size={"large"}
                onClick={() => {
                  handleDownloadClick();
                }}
              />
            </div>
            {/* <p>
            This is an interactive way to ask questions regarding documents in the dataset. Answers
            might not be specific to the question asked but would provide additional relevant
            information.
          </p> */}
            <span className="d-flex justify-center text-xs">
              Ask Auto can make mistakes, check important info.
            </span>
          </div>
        </div>
      )}
      {isSingleDoc && (
        <div
          className={`chat-history-toggle ${!showRightContainer ? "" : "chat-history-toggle-long"}`}
          onClick={() => {
            setShowRightContainer(!showRightContainer);
            setHideChatHistory(!hideChatHistory);
          }}
          title="Chat History"
        >
          {showRightContainer ? <DownOutlined /> : <UpOutlined />}
        </div>
      )}
      {showRightContainer && (
        <div className="chat-aside">
          <ChatAside
            hideChatHistory={hideChatHistory}
            setHideChatHistory={setHideChatHistory}
            chatSource={chatSource}
            setIsQuesAnsLoading={setIsQuesAnsLoading}
            expanded={expanded}
            setExpanded={setExpanded}
            setShowRightContainer={setShowRightContainer}
          />
        </div>
      )}
      {selectedSource && (
        <SourceModal
          open={isSourceModalOpen}
          onClose={handleCloseSourceModal}
          source={selectedSource}
        />
      )}
    </div>
  );
};
