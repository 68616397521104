import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { autonomizeFullLogo, molinaFullLogo } from "../assets/images";
import { useAuthUser } from "../hooks";
import { PAGE_URL } from "../utils";

export const GuestLayout = () => {
  const navigate = useNavigate();
  const { accessToken, roleCode } = useAuthUser();

  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      navigate(PAGE_URL.DASHBOARD);
    }
  }, [accessToken, navigate, roleCode]);

  return (
    <div className="user-page flex aic jcc">
      <div className="user-page-container flex no-wrap jcc aic">
        <div className="brand-logo">
          {window._env_.REACT_APP_IS_CLIENT_ENVIRONMENT?.toLowerCase() === "true" ? (
            <>
              <img
                className="brand-logo-img m-b"
                src={molinaFullLogo}
                alt="Molina Healthcare Logo"
              />
            </>
          ) : (
            <>
              <img className="brand-logo-img m-b" src={autonomizeFullLogo} alt="Autonomize Logo" />
              <h1 className="brand-title tr">
                M<span className="brand-title-highlight">o</span>saic
              </h1>
            </>
          )}
        </div>
        <Outlet />
      </div>
    </div>
  );
};
