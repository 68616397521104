import React from "react";
import { Modal } from "antd";
import { Button } from "antd";
import { DeleteConfirmationProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import "../modal.scss";

const DeleteConfirmation = ({ visibility, handleClose, handleDelete }: DeleteConfirmationProps) => {
  const handleDeleteConfirmation = () => {
    handleDelete();
    handleClose();
  };
  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      title="Delete Modal"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>{"Delete"}</h1>
      </div>
      <div className="deleteConfirmation tc addClient-content">
        {/* <h1>Are you sure?</h1> */}
        <p>
          Are you sure you want to delete this record ?<br /> This action cannot be undone.
        </p>
        <div className="flex jcc gp">
          <Button type="default" className="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="primary" className="fill" onClick={handleDeleteConfirmation}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;
