import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { DeleteIcon } from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useProject } from "../../../hooks";
import {
  RootState,
  openModal,
  updateCanFetchContainers,
  updateDeleteConfirmation,
} from "../../../store";
import { MODAL_TYPE, PAGE_URL, getLocalDateTimeStringFromISO } from "../../../utils";
import { Table, Tooltip } from "antd";
import { Loading } from "../../../components";

interface ITopicDataType {
  key: React.Key;
  name: string;
  createdAt: string;
  action: string;
  status: string | null;
}

const getColumns = (
  handleDeleteProjectClick: (projectId: number) => void
): ColumnsType<ITopicDataType> => [
  {
    title: "Name",
    dataIndex: "name",
    render: (text, record) =>
      record.status === "DELETING_IN_PROGRESS" ? (
        <Tooltip title="Deletion in progress">{text}</Tooltip>
      ) : (
        text
      ),
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: (text, record) =>
      record.status === "DELETING_IN_PROGRESS" ? (
        <Tooltip title="Deletion in progress">{text}</Tooltip>
      ) : (
        text
      ),
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (value, record) =>
      record.status === "DELETING_IN_PROGRESS" ? (
        <Tooltip title="Deletion in progress"><span><DeleteIcon className="link-color" /></span> </Tooltip>
      ) : (
        <DeleteIcon
          className="cursor-pointer link-color"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteProjectClick(Number(record.key));
          }}
        />
      ),
  },
];

export const ProjectsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deleteTopic, deleteProjectLoading, fetchTopics, loading, topics } = useProject();
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const { canFetchContainers } = useSelector((state: RootState) => state.fileFolderUpload);
  const [projectToBeDeleted, setProjectToBeDeleted] = useState(-1);

  useEffect(() => {
    dispatch(updateCanFetchContainers(true));
  }, [dispatch]);

  useEffect(() => {
    if (canFetchContainers) {
      fetchTopics();
      dispatch(updateCanFetchContainers(false));
    }
  }, [canFetchContainers, dispatch, fetchTopics]);

  useEffect(() => {
    const deleteProj = async () => {
      await deleteTopic(projectToBeDeleted);
      dispatch(updateDeleteConfirmation(false));
      dispatch(updateCanFetchContainers(true));
    };
    if (isDeleteConfirmed) {
      deleteProj();
    }
  }, [deleteTopic, dispatch, isDeleteConfirmed, projectToBeDeleted]);

  const handleDeleteProjectClick = (projectId: number) => {
    setProjectToBeDeleted(projectId);
    dispatch(openModal({
      modalName: MODAL_TYPE.DELETE_CONFIRMATION,
      dataSource: "",
    }));
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Table
          className="m-t project-list"
          columns={getColumns(handleDeleteProjectClick)}
          loading={loading || deleteProjectLoading}
          rowClassName={(record) =>
            record.status === "DELETING_IN_PROGRESS" ? "disabled-row" : "cursor-pointer"
          }
          dataSource={topics.map((topic) => ({
            key: topic.id,
            name: topic.projectName ? `${topic.projectName} (${topic.displayName})` : topic.displayName,
            createdAt: getLocalDateTimeStringFromISO(topic.createdAt),
            action: "",
            status: topic.status,
          }))}
          // onRow={(record) =>
          //   record.status !== "DELETING_IN_PROGRESS"
          //     ? {
          //         onClick: () => navigate(`${PAGE_URL.PROJECT_SETTING}/${record.key}`),
          //       }
          //     : {}
          // }
          pagination={false}
        />
      )}
    </>
  );
};