import React, { useCallback, useEffect, useState } from "react";
import { ChatService, IGetAnswerRequest } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  openModal,
  setQuestionsAnswers,
  updateDeleteConfirmation,
} from "../../../store";
import { MODAL_TYPE, STATUS_CODE, getOffsetFromPageAndPageSize, openNotificationWithIcon } from "../../../utils";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { useAuthorization, usePagination, useProject } from "../../../hooks";
import { ChatHistoryList } from "./ChatHistoryList";
import { withAuthorization } from "../../../components";
import Tooltip from "antd/es/tooltip";
interface Props {
  setIsQuesAnsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRightContainer?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthorizedChatHistoryList = withAuthorization(ChatHistoryList);

export const ChatHistory = ({ setIsQuesAnsLoading ,setShowRightContainer}: Props) => {
  const [qaHistory, setQAHistory] = useState([] as { id: string; query: IGetAnswerRequest, createdAt: Date }[]);
  const [totalQA, setTotalQA] = useState(0);
  const { onPaginationChange, page, pageSize } = usePagination();
  const dispatch = useDispatch();
  const { topicId } = useSelector((state: RootState) => state.fileFolderUpload);
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const [isLoading, setIsLoading] = useState(false);
  const { setIsAuthorized } = useAuthorization();
  const { quesAns } = useSelector((state: RootState) => state.quesAns);
  const [initialLoad, setInitialLoad] = useState(true);

  const handleNewChatClick = () => {
    dispatch(setQuestionsAnswers([]));
  };

  const fetchQAHistory = useCallback(async (showMore?: boolean) => {
    setIsLoading(true);
    try {
      const { data } = await ChatService.getQAHistory({
        offset: getOffsetFromPageAndPageSize(page, pageSize),
        limit: pageSize,
        topicId: topicId,
      });
      showMore
        ? setQAHistory((prevState) => [...prevState, ...data.data])
        : setQAHistory(data.data);
      setTotalQA(data.pagination.total ?? 0);
    } catch (error: any) {
      if (error.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", error.response.data.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }, [page, pageSize, topicId]);

  useEffect(() => {
    const clearHistory = async () => {
      try {
        await ChatService.clearHistory(topicId);
        setQAHistory([]);
        dispatch(setQuestionsAnswers([]));
        dispatch(updateDeleteConfirmation(false));
        openNotificationWithIcon("", "History deleted successfully", "success");
      } catch (error: any) {
        openNotificationWithIcon("", error.response.data.message, "error");
      }
    };
    if (isDeleteConfirmed) clearHistory();
  }, [dispatch, isDeleteConfirmed]);

  const handleRefreshClick = () => {
    setQAHistory([]);
    onPaginationChange(1, pageSize);
    fetchQAHistory();
  };

  useEffect(() => {
    if (!initialLoad) {
      if(page == 1) setQAHistory([]);
      fetchQAHistory();
    } else {
      setInitialLoad(false);
    }
  }, [quesAns.length, page]);

  useEffect(() => {
    setQAHistory([]);
    if (page === 1) {
      fetchQAHistory();
    } else if (page !== 1) {
      onPaginationChange(1, pageSize);
    }
  }, [topicId]);

  return (
    <div className="chat-history-container">
      {/* <Button className="fill m-b" disabled={quesAns.length === 0} onClick={handleNewChatClick}>
        New Chat +
      </Button> */}
      <div className="flex aic jcsb gp m-b">
        <h4>Chat History</h4>
        <div className="flex gp aic font-Medium">
          {qaHistory.length !== 0 && (
            <Tooltip title="Clear Chat History">
              <a
                onClick={() =>
                  dispatch(
                    openModal({
                      modalName: MODAL_TYPE.DELETE_CONFIRMATION,
                      dataSource: "",
                    })
                  )
                }
              >
                <DeleteOutlined style={{color:"var(--linkColor)"}} />
              </a>
            </Tooltip>
          )}
          <Tooltip title="New Chat">
            <a onClick={handleNewChatClick}>
              <FormOutlined style={{color:"var(--linkColor)"}}/>
            </a>
          </Tooltip>
        </div>
      </div>
      <AuthorizedChatHistoryList
        {...{
          qaHistory,
          page,
          pageSize,
          onPaginationChange,
          setQAHistory,
          setIsQuesAnsLoading,
          totalQA,
          setTotalQA,
          fetchQAHistory,
          isLoading,
          setShowRightContainer,
        }}
      />
    </div>
  );
};
