import React, { useCallback, useEffect, useState } from "react";
import {
  DATA_EMBEDDING_MODELS_DESCRIPTION,
  STATUS_CODE,
  STORAGE_TYPES,
  openNotificationWithIcon,
} from "../../../utils";
import { TopicsService } from "../../../api/topic";
import { Button, Modal, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ConnectToDataSourceProps } from ".";
import { uploadFileFolderService } from "../../../api";
import { Dropdown, Loading } from "../../../components";
import { RootState, updateProject, updateStorage } from "../../../store";
import { DATASET_TABS_MULTI } from "../../dataset";
import { useDispatch, useSelector } from "react-redux";
import { useProject } from "../../../hooks";
import { SharePointClient } from "../../../api/sharepoint";

interface IDocumentLibrariesOptions{
  id:string;
  name:string;
}

const ConnectToDatSource = ({ handleClose, visibility, dataSource }: ConnectToDataSourceProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [connectingToDataSourceLoading, setConnectingToDataSourceLoading] = useState(false);
  const [containers, setContainers] = useState([] as string[]);
  const [topicName, setTopicName] = useState("");
  const { fetchTopics } = useProject();
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);
  const [documentLibraries, setDocumentLibraries] = useState<IDocumentLibrariesOptions[]>([]);
  const [isDocumentLibrariesLoading, setIsDocumentLibrariesLoading] = useState(true);

  
  const temp = useSelector((state: RootState) => state.fileFolderUpload);
  const [selectedProject, setSelectedProject] = useState({
    id: -1,
    value: "Select a Data Source",
  } as IDropdownOption);
  
  const [selectedDocumentLibrary, setSelectedDocumentLibrary] = useState({
    id: -1,
    value: "Select a Document Library",
  } as IDropdownOption);
  
  const [selectedEmbeddingModel, setSelectedEmbeddingModel] = useState({
    id: -1,
    value: "Choose a project",
  } as IDropdownOption);
  
  const dispatch = useDispatch();


  const fetchAllDriverNames = async (siteName: string) => {
    setIsDocumentLibrariesLoading(true); 
    try {
      const { data } = await uploadFileFolderService.getAllSharePointDrives(siteName,STORAGE_TYPES.SHAREPOINT);
      setDocumentLibraries(data);

      if(!data.length){
        openNotificationWithIcon('','No DocumentLibraries Found For This Site','error');
        return;
      }
        setSelectedDocumentLibrary({
          id: data[0].id,
          value: data[0].name,
        });
      
    } catch (err: any) {
      openNotificationWithIcon('', err.response.message, "error");
    } finally {
      setIsDocumentLibrariesLoading(false); 
    }
  };

  // Fetch unprocessed containers (projects) and set the first project as default
  const fetchNonProcessedContainers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await uploadFileFolderService.getUnprocessedContainers(dataSource);
      setContainers(data.data);

      if (data.data.length > 0) {
        // Automatically select the first project
        const firstProject = data.data[0];
        setSelectedProject({
          id: 0,
          value: firstProject,
        });

        // Fetch driver names for the first project
        fetchAllDriverNames(firstProject);
      } else {
        openNotificationWithIcon("", "No containers found", "error");
        handleClose();
      }
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        handleClose();
      }
    } finally {
      setIsLoading(false);
    }
  }, [handleClose]);

  useEffect(() => {
    fetchNonProcessedContainers();
  }, [fetchNonProcessedContainers]);

  const handleDropdownChange = async (e: any, option: IDropdownOption) => {
    setTopicName(e);
    setSelectedProject(option);
    fetchAllDriverNames(e);
  };

  const createTopicAndIngestDataButtonClick = async () => {
    try {
      setConnectingToDataSourceLoading(true);
      const { data } = await TopicsService.createTopics(
        topicName,
        dataSource,
        project.uuid,
        false,
        selectedProject.value,
        selectedDocumentLibrary.id
      );

      dispatch(updateProject({
        ...project,
        storage: dataSource,
        sourceName: selectedProject.value,
      }));

      openNotificationWithIcon(
        "",
        `${topicName} topic created successfully. Fetching file from the data source`,
        "success"
      );
      handleClose();
      fetchTopics();
      dispatch(updateStorage(""));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setConnectingToDataSourceLoading(false);
      dispatch(updateStorage(""));
    }
  };
  // Render document libraries dropdown

  const submitButtonActive=()=>{
    return (
      topicName.trim() === "" || 
      temp.topicId !== -1 || 
      isDocumentLibrariesLoading || 
      isLoading || 
      selectedDocumentLibrary.id===-1
    )
}
  const renderDocumentLibraries = () => {

    return (
      <div>
        <div className="flex aic jcsb m-b m-t">
          <p className="font-Semibold">Document Libraries: </p>
        </div>
        <div className="m-b">
          <Dropdown
            options={documentLibraries.map((library: any) => ({
              value: library.name,
              id: library.id,
              key: library.id,
            }))}
            defaultValue={documentLibraries[0]?.name}
            onSelect={(e, option) => {
              setSelectedDocumentLibrary(option);
            }}
            loading={isDocumentLibrariesLoading} 
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <h1>{DATASET_TABS_MULTI.CONNECT_TO_DATA_SOURCE}</h1>
      </div>
      <div className="addClient-content modal-form-content">
        <div className="flex aic jcsb m-b">
          <p className="font-Semibold">Data Source: </p>
        </div>
        <div className="m-b" style={{ height: "200px" }}>
          <Dropdown
            options={containers.map((container, index) => ({
              value: container,
              id: index,
              key: index,
            }))}
            loading={isLoading}
            onSelect={handleDropdownChange}
            value={selectedProject.value}
          />
          {/* Show document libraries dropdown if libraries are available */}
          {renderDocumentLibraries()}
        </div>

        <div className="m-t flex aic gp-10 jce">
          <Button
            className="outline"
            onClick={createTopicAndIngestDataButtonClick}
            disabled={submitButtonActive()}
            loading={connectingToDataSourceLoading}
          >
            Select Data Source
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectToDatSource;


