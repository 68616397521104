import React from "react";
import { IconsProps } from "./Icons";

export const InformationIcon = ({ className, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 17 17"}
      width={width ? width : "17"}
      height={height ? height : "17"}
      fill="none"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.72266 8.63624C2.72266 5.37026 5.37026 2.72266 8.63624 2.72266C11.9022 2.72266 14.5498 5.37026 14.5498 8.63624C14.5498 11.9022 11.9022 14.5498 8.63624 14.5498C5.37026 14.5498 2.72266 11.9022 2.72266 8.63624ZM8.63624 0.722656C4.26569 0.722656 0.722656 4.26569 0.722656 8.63624C0.722656 13.0068 4.26569 16.5498 8.63624 16.5498C13.0068 16.5498 16.5498 13.0068 16.5498 8.63624C16.5498 4.26569 13.0068 0.722656 8.63624 0.722656ZM8.63612 4.87081C8.08383 4.87081 7.63612 5.31852 7.63612 5.87081C7.63612 6.42309 8.08383 6.87081 8.63612 6.87081H8.64303C9.19531 6.87081 9.64303 6.42309 9.64303 5.87081C9.64303 5.31852 9.19531 4.87081 8.64303 4.87081H8.63612ZM9.63612 8.63624C9.63612 8.08395 9.1884 7.63624 8.63612 7.63624C8.08383 7.63624 7.63612 8.08395 7.63612 8.63624V11.4017C7.63612 11.954 8.08383 12.4017 8.63612 12.4017C9.1884 12.4017 9.63612 11.954 9.63612 11.4017V8.63624Z"
        fill="currentColor"
      />
    </svg>
  );
};
