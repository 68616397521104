import React, { useEffect, useState } from "react";
import { Collapse, Input, Select, Checkbox, Button, Form } from "antd";
import { useForm, Controller } from "react-hook-form";
import { CREATE_PROJECTS } from "./index";
import { PaginationLeftArrowIcon, PaginationRightArrowIcon } from "../../assets/icons";
import { useFetchDocumentIngestionSettings } from "../../hooks";
import "./projectContainer.scss";
import { useNavigate } from "react-router-dom";
import { ProjectsService, SettingsService } from "../../api";
import { DATASET_TABS_MULTI } from "../dataset";
import { openNotificationWithIcon, PROJECT_TYPE } from "../../utils";
import { updateProject } from "../../store";
import { useDispatch } from "react-redux";

const { Panel } = Collapse;
const { TextArea } = Input;

type FormValues = {
  displayName: string;
  description: string;
  typeId: string;
  typeSettingIds: string[];
  generativeAIModelId: string;
  systemPrompt: string;
  userPrompt: string;
  embeddingModelId: number;
  chunkSizeId: number;
  chunkingStrategyId: number;
};

const ProjectContainer = () => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(CREATE_PROJECTS.GENERAL_SETTINGS);
  const [selectedProjectSettings, setSelectedProjectSettings] = useState(
    [] as IProjectTypeSetting[]
  );
  const dispatch = useDispatch();
  const [userPrompts, setUserPrompts] = useState("");
  const [systemPrompts, setSystemPrompts] = useState("");
  const [ submitting, setSubmitting] = useState(false);

  const {
    chunkSizes,
    embeddingModels,
    getDropdownsData,
    isDropdownsDataLoading,
    tokenizers,
    selectedChunkSize,
    selectedEmbeddingModel,
    selectedTokenizer,
    getProjectTypes,
    projectTypes,
    generativeAiModels,
    getGenerativeAiModel,   
  } = useFetchDocumentIngestionSettings();
  
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors, isValid},
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      displayName: "",
      description: "",
      typeId: "",
      typeSettingIds: [],
      generativeAIModelId: "",
      systemPrompt: systemPrompts,
      userPrompt: userPrompts,
      embeddingModelId: 0,
      chunkSizeId: 0,
      chunkingStrategyId: 0,
    },
  });

  const fetchAllData = async () => {
    try {
      
      getDropdownsData(),
      getGenerativeAiModel(),
      getProjectTypes()
      
      const defaultMessages: any = await SettingsService.getDefaultMessage();
      const fetchedUserPrompts = JSON.parse(defaultMessages.data[1].value)?.value;
      const fetchedSystemPrompts = JSON.parse(defaultMessages.data[0].value).value;
      setUserPrompts(fetchedUserPrompts);
      setSystemPrompts(fetchedSystemPrompts);
      reset({
        userPrompt: fetchedUserPrompts,
        systemPrompt: fetchedSystemPrompts,
      });  
    } catch (error) {
      console.error("Error in fetchAllData:", error);
    }
    
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    if (selectedEmbeddingModel.id !== undefined && selectedEmbeddingModel.id !== -1) {
      setValue("embeddingModelId", selectedEmbeddingModel.id);
    } else {
      setValue("embeddingModelId", "" as unknown as number);
    }
    if (selectedChunkSize.id !== undefined && selectedChunkSize.id !== -1) {
      setValue("chunkSizeId", selectedChunkSize.id);
    } else {
      setValue("chunkSizeId", "" as unknown as number);
    }
    if (selectedTokenizer.id !== undefined && selectedTokenizer.id !== -1) {
      setValue("chunkingStrategyId", selectedTokenizer.id);
    } else {
      setValue("chunkingStrategyId", "" as unknown as number);
    }
  }, [selectedEmbeddingModel, selectedChunkSize, selectedTokenizer, setValue]);

  const projectType: any = watch("typeId");
  useEffect(() => {
    if (projectType) {
      const selectedType = projectTypes.find((type) => type.id === projectType);
      if (selectedType) {
        setSelectedProjectSettings(selectedType.settings);
        setValue("typeSettingIds", []);
      }
    }
  }, [projectType, projectTypes]);

  const getGenerativeAndChunkAPICall = () => {
    if (!chunkSizes.length) getDropdownsData();
    if (!generativeAiModels.length) getGenerativeAiModel();
  };

  const handlePanelChange = (key: any) => {
    setActiveKey(key[0]);
    if (key[0] === CREATE_PROJECTS.ADVANCED_SETTINGS) getGenerativeAndChunkAPICall();
    else if (key[0] === CREATE_PROJECTS.QA_SETTINGS && !projectTypes.length) {
      getProjectTypes();
    }
  };

  const handleNext = () => {
    const panels = Object.values(CREATE_PROJECTS);
    const currentIndex = panels.indexOf(activeKey);
    if (currentIndex === 1) getGenerativeAndChunkAPICall();
    else if (currentIndex === 0 && !projectTypes.length) getProjectTypes();
    if (currentIndex < panels.length - 1) {
      setActiveKey(panels[currentIndex + 1]);
    }
  };

  const onSubmit = async (payload: any) => {
    try {
      setSubmitting(true);
      payload.embeddingModelValue = embeddingModels.find(
        (item) => item.id === payload.embeddingModelId
      )!.value;
      if (payload.typeSettingIds.includes("None")) {
        payload.typeSettingIds = [];
      }
      const { data } = await ProjectsService.createProjects(payload);
      openNotificationWithIcon("", "Project created successfully", "success");
      dispatch(updateProject(data.data))
      navigate(
        `/projects/${data.data.uuid.toLowerCase()}/datasets?tab=${
          DATASET_TABS_MULTI.CONNECT_TO_DATA_SOURCE
        }`
      );
    } catch(error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setSubmitting(false);
    }
  };
  

  const renderGeneralSettingsPanel = () => {
    return (
      <Panel header={CREATE_PROJECTS.GENERAL_SETTINGS} key={CREATE_PROJECTS.GENERAL_SETTINGS}>
        <div className="form-item">
          <label htmlFor="projectName">Project Name</label>
          <Controller
            name="displayName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input {...field} placeholder="Enter Name" />}
          />
          {errors.displayName && errors.displayName.type === "required" && (
            <span className="error">* Project name is required</span>
          )}
        </div>
        <div className="form-item">
          <label htmlFor="projectDescription">Project Description</label>
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextArea {...field} placeholder="Enter Description" style={{ height: "120px" }} />
            )}
          />
          {errors.description && errors.description.type === "required" && (
            <span className="error">* Project Description is required</span>
          )}
        </div>
        <div className="form-button">
          <Button onClick={handleNext} className="outline">
            Next
          </Button>
        </div>
      </Panel>
    );
  };

  const renderQaTpeSettings = () => {
    if(!getValues("typeId")) return <></>;

    return (
      <div className="form-item">
        <label htmlFor="qaConfiguration">Q&A Configuration</label>
        <Controller
          name="typeSettingIds"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Checkbox.Group
              {...field}
              onChange={(checkedValues: string[]) => {
                const prevValues = getValues("typeSettingIds") as string[];

                if(prevValues?.includes("None")){
                  return field.onChange(checkedValues.filter(type => type != "None"))
                }

                if(!prevValues?.includes("None") && checkedValues.includes("None")){
                  return field.onChange(["None"])
                }

                if(checkedValues.includes("None") && checkedValues.length > 1) {
                  return field.onChange(prevValues.filter(type => type !== "None"));
                }

                field.onChange(checkedValues);
              }}
              className="flex-column mt-5"
            >
              {selectedProjectSettings.map((setting) => (
                <Checkbox key={setting.id} value={setting.id}>
                  {setting.displayName}
                </Checkbox>
              ))}
              <Checkbox value="None">None</Checkbox>
            </Checkbox.Group>
          )}
        />
        {errors.typeSettingIds && errors.typeSettingIds.type === "required" && (
          <span className="error">* Q&A Configuration is required</span>
        )}
      </div>
    );
  }

  const renderQaSettingsPanel = () => {
    return (
      <Panel header={CREATE_PROJECTS.QA_SETTINGS} key={CREATE_PROJECTS.QA_SETTINGS}>
        <div className="form-item">
          <label htmlFor="projectType">Project Type</label>
          <Controller
            name="typeId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select {...field} placeholder="Select Project Type" loading={isDropdownsDataLoading}>
                {projectTypes.map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.value}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
          {errors.typeId && errors.typeId.type === "required" && (
            <span className="error">* Project type is required</span>
          )}
        </div>
          {renderQaTpeSettings()}
        <div className="form-button">
          <Button onClick={handleNext} className="outline">
            Next
          </Button>
        </div>
      </Panel>
    );
  };

  const renderAdvancedSettingsPanel = () => {
    return (
      <Panel header={CREATE_PROJECTS.ADVANCED_SETTINGS} key={CREATE_PROJECTS.ADVANCED_SETTINGS}>
        <div className="form-item">
          <h4 className="pt-10 my-10">Document Ingestion Settings</h4>
          <label htmlFor="embeddingModels">Embedding Models</label>
          <Controller
            name="embeddingModelId"
            control={control}
            defaultValue={selectedEmbeddingModel.id}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Select Embedding Model"
                loading={isDropdownsDataLoading}
                defaultValue={selectedEmbeddingModel.id}
              >
                {embeddingModels.map((model) => (
                  <Select.Option key={model.id} value={model.id}>
                    {model.value}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </div>

        <div className="inline-form-items">
          <div className="form-item">
            <label htmlFor="chunkSize">Chunk Size</label>
            <Controller
              name="chunkSizeId"
              control={control}
              defaultValue={selectedChunkSize.id}
              render={({ field }) => (
                <Select {...field} placeholder="Select Chunk Size" loading={isDropdownsDataLoading} defaultValue={selectedChunkSize.id}>
                  {chunkSizes.map((size) => (
                    <Select.Option key={size.id} value={size.id}>
                      {size.value}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </div>
          <div className="form-item">
            <label htmlFor="chunkingStrategy">Chunking Strategy</label>
            <Controller
              name="chunkingStrategyId"
              control={control}
              defaultValue={selectedTokenizer.id}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Select Chunking Strategy"
                  loading={isDropdownsDataLoading}
                  defaultValue={selectedTokenizer.id}
                >
                  {tokenizers.map((tokenizer) => (
                    <Select.Option key={tokenizer.id} value={tokenizer.id}>
                      {tokenizer.value}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </div>
        </div>
        <hr />
        <h4 className="pt-10 my-10">Generative AI Parameters</h4>
        <div className="form-item">
          <label htmlFor="generativeAIModelId">Generative AI Model</label>
          <Controller
            name="generativeAIModelId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Select Generative Model"
                loading={isDropdownsDataLoading}
              >
                {generativeAiModels.map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.value}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
          {errors.generativeAIModelId && errors.generativeAIModelId.type === "required" && (
            <span className="error">* Generative AI model is required</span>
          )}
        </div>
        <div className="form-item">
          <label htmlFor="systemPrompt">System Prompt</label>
          <Controller
            name="systemPrompt"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <TextArea {...field} placeholder="Enter Description" />}
          />
          {errors.systemPrompt && errors.systemPrompt.type === "required" && (
            <span className="error">* System prompt is required</span>
          )}
        </div>
        <div className="form-item">
          <label htmlFor="userPrompt">User Prompt</label>
          <Controller
            name="userPrompt"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <TextArea {...field} placeholder="Enter Description" />}
          />
          {errors.userPrompt && errors.userPrompt.type === "required" && (
            <span className="error">* User prompt is required</span>
          )}
        </div>
      </Panel>
    );
  };

  const allFieldsFilled = () => {
    const values = watch();
    return (
      values.displayName &&
      values.description &&
      ((values.typeSettingIds && values.typeSettingIds.length > 0)) &&
      values.typeId &&
      values.systemPrompt &&
      values.userPrompt &&
      values.embeddingModelId &&
      values.chunkSizeId &&
      values.chunkingStrategyId &&
      values.generativeAIModelId
    );
  };
  
  const renderActionButtons = () => {
    return (
      <div className="create-form-button">
        <Button
          className="outline mr-10"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Cancel
        </Button>
        <Button className="fill" htmlType="submit" disabled={!allFieldsFilled()} loading={submitting}>
          Create New Project
        </Button>
      </div>
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="create-project-form-container flex-column flex-center"
    >
      <Collapse
        accordion
        activeKey={activeKey}
        onChange={handlePanelChange}
        expandIconPosition="end"
        style={{ width: "70%" }}
        expandIcon={(p) =>
          p.isActive ? <PaginationLeftArrowIcon /> : <PaginationRightArrowIcon />
        }
      >
        {renderGeneralSettingsPanel()}
        {renderQaSettingsPanel()}
        {renderAdvancedSettingsPanel()}
      </Collapse>
      {renderActionButtons()}
    </form>
  );
};

export default ProjectContainer;
