import React, { useState } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Progress, UploadProps, message } from "antd";
import { UploadFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import "./file-uploader.scss";
import { openNotificationWithIcon } from "../../../../utils";

interface Props {
  onSubmit: (file: UploadFile<any>) => Promise<void>;
  onFilesUploadComplete?: (files: string[]) => Promise<void>;
  validateFileName?: (fileName: string) => boolean;
  validateFileNameErrorMessage?: string;
  formats?: string[];
  storage?: string;
}
const acceptedFormats = [".pdf", ".docx", ".txt"];

export const FileUploader = ({
  onSubmit,
  onFilesUploadComplete,
  validateFileName,
  validateFileNameErrorMessage,
  formats,
  storage,
}: Props) => {
  const [files, setFiles] = useState([] as UploadFile<any>[]);
  const [loading, setLoading] = useState(false);
  const [pollingCounter, setPollingCounter] = useState<number>(0);
  const [isUploading, setIsUploading] = useState(false);

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: acceptedFormats.join(","),
    beforeUpload: (file, fileList) => {
      const isImage = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      if (isImage) message.error("Image upload not supported!");
      else {
        setFiles([...fileList, ...files]);
      }
      return isImage;
    },
    onRemove: (file: UploadFile<any>) => {
      if (loading) {
        message.error("Cannot remove file during upload!");
      } else {
        const fileIndex = files.findIndex((item) => item.name === file.name);
        if (fileIndex !== -1) {
          setFiles([...files.slice(0, fileIndex), ...files.slice(fileIndex + 1)]);
        }
      }
    },
    fileList: files,
  };

  const handleFileSubmit = async (file: UploadFile<any>) => {
    try {
      setIsUploading(true);
      await onSubmit(file);
      // openNotificationWithIcon("", `${file.name} uploaded successfully!`, "success");
    } catch {
      openNotificationWithIcon("", `Could not upload ${file.name}, please try again!`, "error");
    } finally {
      setIsUploading(false);
    }
  };

  const handleFilesSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setLoading(true);
    const uploadedFiles: { uid: string; name: string }[] = [];
    for (const file of files) {
      if (validateFileName && validateFileNameErrorMessage) {
        if (validateFileName(file.name)) {
          await handleFileSubmit(file);
          uploadedFiles.push({ name: file.name, uid: file.uid });
        } else {
          openNotificationWithIcon("", validateFileNameErrorMessage, "error");
        }
      } else {
        await handleFileSubmit(file);
        uploadedFiles.push({ name: file.name, uid: file.uid });
      }
    }
    onFilesUploadComplete && onFilesUploadComplete(uploadedFiles.map((item) => item.name));
    const uploadedFileUID = uploadedFiles.map((item) => item.uid);
    setFiles([...files.filter((file) => !uploadedFileUID.includes(file.uid))]);
    setLoading(false);
  };
  return (
    <div className="attachment">
      <Dragger {...props}>
        <div className="attachment-uploadbtn">
          <div className="attachment-content">
            <CloudUploadOutlined style={{ fontSize: "60px" }} />
            <div className="attachment-content-text">
              <p>
                Drag & drop file here or{" "}
                <span className="font-semibold link-color">Browse File</span>
              </p>
              {acceptedFormats.length > 0 && (
                <>
                  <p className="italic">Supported files types:{acceptedFormats.join(", ")}</p>
                  <p className="italic">
                    Note: If uploading multiple files and want to add metadata. please create a
                    folder first.
                  </p>
                </>
              )}
            </div>
          </div>
          <Button
            style={{ marginTop: "12px" }}
            className="fill"
            onClick={handleFilesSubmit}
            disabled={files.length === 0 || isUploading}
            loading={isUploading}
          >
            Upload
          </Button>
        </div>
      </Dragger>
      {pollingCounter !== 0 && (
        <div style={{ display: "flex" }}>
          <Progress
            status="active"
            strokeLinecap="round"
            type="line"
            style={{ marginBottom: "4px" }}
            percent={pollingCounter}
          />
        </div>
      )}
    </div>
  );
};
