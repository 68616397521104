import React, { useCallback, useEffect, useState } from "react";
import "./single-doc-qa-list.scss";
import { Table } from "antd";
import { PAGE_URL, getLocalDateTimeStringFromISO, getOffsetFromPageAndPageSize } from "../../utils";
import { FileFolder, uploadFileFolderService } from "../../api";
import { usePagination } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateDocument } from "../../store";
import { AppPagination } from "../../components";
import { useNavigate } from "react-router-dom";

const SingleDocQAList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filesList, setFilesList] = useState<FileFolder[]>([]);
  const { topicId, project } = useSelector((state: RootState) => state.fileFolderUpload);
  const { page, pageSize,onPaginationChange,pageSizeOptions } = usePagination();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [total,setTotal]=useState(0);
  const refactoredFileList = filesList
    .filter((item) => !item.isFolder)
    .map((item, index) => ({
      createdOn: getLocalDateTimeStringFromISO(item.createdAt),
      name: item.name,
      key: index,
      documentUuid: item.document_uuid,
    }));

  const getColumns = () => [
    {
      title: "Files",
      key: "name",
      dataIndex: "name",
      render: (name: string, file: any) => (
        <div>
          {!file.isFolder ? (
            <span
              onClick={() => {
                navigate(
                  `${PAGE_URL.PROJECTS}/${project.uuid}${PAGE_URL.ASK_AUTO}/${file.documentUuid}`
                );
                dispatch(updateDocument(file));
              }}
            >
              {name}
            </span>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      title: "Created At",
      key: "createdOn",
      dataIndex: "createdOn",
    },
  ];

  const fetchFiles = useCallback(async () => {
    setIsLoading(true);
    const { data } = await uploadFileFolderService.getAllFilesFromTopic(
      topicId,
      pageSize,
      getOffsetFromPageAndPageSize(page, pageSize),
      "READY"
    );
    setTotal(data.pagination.total);
    setFilesList(data.data);
    setIsLoading(false);
  }, [topicId, page,pageSize]);

  useEffect(() => {
    if (topicId !== -1) {
      fetchFiles();
    }
  }, [page, topicId,pageSize]);
  return (
    <div className="single-doc-qa-list">
      <Table
        rowClassName={"cursor-pointer"}
        loading={isLoading}
        columns={getColumns()}
        pagination={false}
        dataSource={refactoredFileList}
        className="file-table"
      />
      <AppPagination
        {...{
          showSizeChanger: true,
          current: page,
          total: total,
          pageSize,
          pageSizeOptions,
          onChange: onPaginationChange,
        }}
      />
    </div>
  );
};

export default SingleDocQAList;
